body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F8F9FA;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.node-label {
    font-size: 12px;
    padding: 1px 4px;
    border-radius: 4px;
    background-color: rgba(0,0,0,0.5);
    user-select: none;
}

.button-orange,
.button-orange:active,
.button-orange:visited,
.button-orange:focus {
    background-color: #EE7500 !important;
    border-color: #EE7500 !important;
    color: #F8F9FA !important;
}

.button-orange:hover {
    background-color: #EE7500 !important;
    border-color: #EE7500 !important;
    color: #F8F9FA !important;
}

.toolbar-button:hover {
    color: #EE7500 !important;
}

.delete-button:hover {
    color: #DC3445 !important;
}

.scrollbar-measure-temp-style {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}

.toolbar {
    display: flex;
}

#print-table>tr>td, #print-table>tr>th {
    padding: 0;
}

.collapse-table {
    border-collapse: collapse;
}

.collapse-table th, td {
    padding: 1px 7px;
}

@media screen {
    .page-header {
        display: none;
    }
}

@media print {
    .toolbar {
        display: none;
    }
    .page-break {
        page-break-before: always;
    }
}